<template>
  <div class="my-settings">
    <Loader v-if="$apollo.queries.profile.loading" size="50" color="#FF035A" />
    <div class="content" v-else>
      <span class="section-title">Company Details</span>
      <div class="section-content">
        <div class="profile-form">
          <span class="label">
            Company Name
          </span>
          <input type="text" class="text-input" v-model="editable.name" />
          <span class="label">
            Address
          </span>
          <AddressInput
            v-model="editable.address"
            title="Address"
            theme="light"
          />
          <div class="buttons">
            <button
              class="primary"
              :disabled="!updated || !valid || submitting"
              @click="updateProfile"
            >
              {{ submitting ? "Updating..." : "Update" }}
            </button>
          </div>
        </div>
      </div>
      <div class="section-content">
        <div class="users">
          <div class="title">Representatives</div>
          <table>
            <thead>
              <tr>
                <th>CONTACT</th>
                <th colspan="2">ACCESS</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="{ id, access, user } in activeUsers" :key="id">
                <td class="user">
                  <div class="user-dp">
                    <img :src="user.avatar_url" v-if="user.avatar_url" />
                    <div class="initials" v-else>
                      {{ (user.first_name + " " + user.last_name) | initials }}
                    </div>
                  </div>
                  <div class="user-details">
                    <span>{{ user.first_name + " " + user.last_name }}</span>
                    <span>{{ user.email }}</span>
                  </div>
                </td>
                <td>
                  <CustomSelect
                    :val="access"
                    default="Contributor"
                    @setValue="value => updateCompanyUser(id, 'access', value)"
                    :options="[
                      { value: 'contributor', label: 'Contributor' },
                      { value: 'full', label: 'Full Access' }
                    ]"
                    no-margin
                  />
                </td>
                <td v-if="updating.includes(id)">
                  <Loader color="#FF035A" size="24" />
                </td>
                <td v-else-if="canUpdateTeam && userId !== user.id">
                  <div class="remove-container">
                    <button
                      class="remove"
                      @click="updateCompanyUser(id, 'status', 'removed')"
                    >
                      <span class="minus" />
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="row add" @click="toggleAddRepresentativeModal">
            <button class="add-button">+</button>
            <span>Add Another Representative</span>
          </div>
        </div>
      </div>
      <div class="section-content">
        <div class="users">
          <div class="title">Artists</div>
          <table>
            <thead>
              <tr>
                <th>ARTIST</th>
                <th class="right">RECORDING AGREEMENT</th>
                <th class="right">(THIRD-PARTY)<br />RECORDING AGREEMENT</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="{
                  id,
                  artist,
                  recording_agreement,
                  recording_agreement_third_party
                } in profile.company_artists"
                :key="id"
              >
                <td class="user">
                  <div class="user-dp">
                    <img :src="artist.avatar_url" v-if="artist.avatar_url" />
                    <div class="initials" v-else>
                      {{ artist.name | initials }}
                    </div>
                  </div>
                  <div class="user-details-one">
                    <span>{{ artist.name }}</span>
                  </div>
                </td>
                <td class="right">
                  <CustomSelect
                    :options="[
                      { value: true, label: 'Yes' },
                      { value: false, label: 'No' }
                    ]"
                    @setValue="
                      value =>
                        updateCompanyArtist(id, 'recording_agreement', value)
                    "
                    class="rep-selector"
                    :val="recording_agreement"
                    default="Has recording agreement?"
                    noMargin
                  />
                </td>
                <td class="right">
                  <CustomSelect
                    :options="[
                      { value: true, label: 'Yes' },
                      { value: false, label: 'No' }
                    ]"
                    @setValue="
                      value =>
                        updateCompanyArtist(
                          id,
                          'recording_agreement_third_party',
                          value
                        )
                    "
                    class="rep-selector"
                    :val="recording_agreement_third_party"
                    default="Has recording agreement with 3rd party?"
                    noMargin
                  />
                </td>
                <td>
                  <Loader
                    v-if="updating.includes(id)"
                    color="#FF035A"
                    size="24"
                  />
                  <div class="no-loader" v-else />
                </td>
              </tr>
            </tbody>
          </table>
          <div class="row add" @click="toggleAddArtistModal">
            <button class="add-button">+</button>
            <span>Add Artist / Producer</span>
          </div>
        </div>
      </div>
    </div>
    <AddRepresentative
      v-if="addingRepresentative"
      v-model="newRep"
      @close="toggleAddRepresentativeModal"
      :valid="newRepValid"
      :companyId="profile.id"
      :onboarded="true"
    />
    <AddArtist
      v-if="addingArtist"
      v-model="newArtist"
      @close="toggleAddArtistModal"
      :valid="newArtistValid"
      :companyId="profile.id"
      :companyName="profile.name"
      :onboarded="true"
    />
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import Loader from "@/components/Loader.vue";
import AddressInput from "@/components/AddressInput.vue";
import CustomSelect from "@/components/Select.vue";
import AddRepresentative from "@/components/modals/AddRepresentative.vue";
import AddArtist from "@/components/modals/AddArtist.vue";

import GET_COMPANY from "@/api/queries/GET_COMPANY.gql";
import UPDATE_COMPANY from "@/api/mutations/UPDATE_COMPANY.gql";
import UPDATE_COMPANY_USER from "@/api/mutations/UPDATE_COMPANY_USER.gql";
import UPDATE_COMPANY_ARTIST from "@/api/mutations/UPDATE_COMPANY_ARTIST.gql";

export default {
  name: "CompanySettings",
  components: {
    Loader,
    AddressInput,
    CustomSelect,
    AddRepresentative,
    AddArtist
  },
  data() {
    return {
      editable: {},
      submitting: false,
      updating: [],
      repOptions: [
        { value: "label", label: "Label" },
        { value: "loan_out_company", label: "Loan-out Company" }
      ],
      newRep: {},
      addingRepresentative: false,
      newArtist: {},
      addingArtist: false
    };
  },
  computed: {
    signedToOptions() {
      return [
        { value: "none", label: "No" },
        { value: "to_company", label: `Yes (to ${this.profile.name})` },
        { value: "to_third_party", label: "Yes (to third-party label)" }
      ];
    },
    canUpdateTeam() {
      const { userId, profile } = this;
      return !!profile.company_users.find(
        el => el.access === "full" && el.user.id === userId
      );
    },
    updated() {
      const { editable, profile } = this;
      return Object.keys(profile).some(el => profile[el] !== editable[el]);
    },
    valid() {
      const {
        editable: { name, address }
      } = this;
      return !!name && !!address;
    },
    userId() {
      return this.$store.getters["account/getUserId"];
    },
    activeUsers() {
      return this.profile.company_users.filter(
        ({ status }) => status === "active"
      );
    },
    newRepValid() {
      const { firstName, lastName, email } = this.newRep;
      return !!firstName && !!lastName && !!email;
    },
    newArtistValid() {
      return (
        !!this.newArtist.firstName &&
        !!this.newArtist.lastName &&
        !!this.newArtist.email &&
        !!this.newArtist.talentName &&
        !!this.newArtist.PROAffiliation &&
        !!this.newArtist.songwriterIPINo &&
        !!this.newArtist.address &&
        !!this.newArtist.recording_agreement &&
        !!this.newArtist.recording_agreement_third_party
      );
    }
  },
  filters: {
    initials(name) {
      return name
        .split(" ")
        .slice(0, 2)
        .map(el => el.charAt(0).toUpperCase())
        .join("");
    }
  },
  methods: {
    toggleAddRepresentativeModal() {
      this.addingRepresentative = !this.addingRepresentative;
      this.newRep = {};
    },
    toggleAddArtistModal() {
      this.addingArtist = !this.addingArtist;
      this.newArtist = {};
    },
    updateCompanyUser(id, field, value) {
      this.updating.push(id);
      this.$apollo.mutate({
        mutation: UPDATE_COMPANY_USER,
        variables: { id, update: { [field]: value } },
        update: (store, { data: { update_company_users_by_pk } }) => {
          const data = store.readQuery({
            query: GET_COMPANY,
            variables: {
              userId: this.$store.getters["account/getUserId"]
            }
          });

          const index = data.companies[0].company_users.findIndex(
            el => el.id === id
          );

          data.companies[0].company_users[index] = {
            ...data.companies[0].company_users[index],
            ...update_company_users_by_pk
          };

          store.writeQuery({
            query: GET_COMPANY,
            variables: {
              userId: this.$store.getters["account/getUserId"]
            },
            data
          });

          this.updating = this.updating.filter(el => el !== id);
        }
      });
    },
    updateCompanyArtist(id, field, value) {
      this.updating.push(id);

      const variables = {
        id,
        update: {
          [field]: value
        }
      };

      this.$apollo.mutate({
        mutation: UPDATE_COMPANY_ARTIST,
        variables,
        update: (store, { data: { update_company_artists_by_pk } }) => {
          const data = store.readQuery({
            query: GET_COMPANY,
            variables: {
              userId: this.$store.getters["account/getUserId"]
            }
          });

          const index = data.companies[0].company_artists.findIndex(
            el => el.id === id
          );

          data.companies[0].company_artists[index] = {
            ...data.companies[0].company_artists[index],
            [field]: update_company_artists_by_pk[field]
          };

          store.writeQuery({
            query: GET_COMPANY,
            variables: {
              userId: this.$store.getters["account/getUserId"]
            },
            data
          });

          this.updating = this.updating.filter(el => el !== id);
        }
      });
    },
    updateProfile() {
      this.submitting = true;
      const { id, name, address } = this.editable;
      const variables = { id, update: { name, address } };

      return this.$apollo.mutate({
        mutation: UPDATE_COMPANY,
        variables,
        update: () => {
          this.submitting = false;
        }
      });
    }
  },
  apollo: {
    profile: {
      query: GET_COMPANY,
      variables() {
        return { userId: this.$store.getters["account/getUserId"] };
      },
      update({ companies }) {
        const company = companies[0];
        this.editable = { ...company };
        return { ...company };
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
.my-settings {
  width: 100%;
  height: 100%;
  .loader-container {
    height: calc(100vh - 110px) !important;
  }
  .content {
    padding-top: 3rem;
    overflow-y: auto;
    height: 100%;
    &::-webkit-scrollbar {
      /* width of the entire scrollbar */
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      /* color of the tracking area */
      @include background-opacity(#cdcccc, 0.3);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      /* color of the scroll thumb */
      background-color: $accent;
      /* roundness of the scroll thumb */
      border-radius: 5px;
    }
    .section-title {
      display: block;
      margin-left: calc(5% + 3rem);
      font-size: 1.15rem;
      font-weight: 600;
    }
    .section-subtitle {
      display: block;
      margin: 0.8rem calc(5% + 3rem) 0;
      font-weight: 200;
    }
    .section-content {
      margin: 0 5%;
      padding: 1.5rem 3rem;
      .profile-form {
        display: flex;
        flex-direction: column;
        .label {
          margin-bottom: 10px;
        }
        input {
          margin-bottom: 30px;
        }
        .inline {
          display: flex;
          justify-content: space-between;
          input {
            width: 48%;
          }
        }
      }
      .payment-methods,
      .invoices {
        .check {
          margin-top: 2px;
          width: 24px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: $accent;
          overflow: hidden;
          border-radius: 50%;
          img {
            object-fit: cover;
            width: 12px;
            height: 12px;
          }
        }
        .payment-details {
          display: flex;
          align-items: center;
          .brand-container {
            width: 48px;
            display: flex;
            justify-content: center;
            img {
              height: 34px;
            }
          }
          span {
            margin-left: 1rem;
          }
          .card-number {
            font-weight: 600;
          }
          .card-expiry {
            font-weight: 600;
            color: $text-additional;
          }
        }
        .actions {
          text-align: right;
          padding: 0;
          position: relative;
          .icon {
            display: flex;
            flex-direction: column;
            background-color: #e0e0e0;
            align-items: center;
            justify-content: space-between;
            height: 24px;
            width: 24px;
            border-radius: 50%;
            padding: 4px 0;
            margin-left: auto;
            &:hover {
              cursor: pointer;
              background-color: #c9c9c9;
            }
            .dot {
              height: 4px;
              width: 4px;
              border-radius: 50%;
              background-color: $white;
            }
          }
          .menu {
            position: absolute;
            background-color: $bg;
            top: calc(50% - 12px);
            right: 0;
            display: flex;
            flex-direction: column;
            border: 1px solid #ebebeb;
            border-radius: 10px;
            z-index: 11;
            &:focus {
              outline: none;
            }
            .item {
              text-align: left;
              font-size: 0.8rem;
              white-space: nowrap;
              padding: 8px 12px;
              &:hover {
                background-color: #f3f1f1;
                cursor: pointer;
              }
            }
          }
        }
      }
      .plans {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .pro {
          border: 2px solid $accent;
        }
        .plan {
          width: 45%;
          background-color: $gray;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 12px 24px 14px;
          border-radius: 20px;
          .details {
            display: flex;
            flex-direction: column;
            .name {
              font-size: 1.1rem;
            }
            .price {
              font-weight: 100;
            }
          }
          .check {
            margin-top: 2px;
            width: 25px;
            height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: $accent;
            overflow: hidden;
            border-radius: 50%;
            img {
              object-fit: cover;
              width: 15px;
            }
          }
          .action {
            button {
              margin-top: 2px;
              padding: 10px 18px 13px;
            }
          }
          .action-pro {
            flex-grow: 1;
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-start;
            align-items: center;
            height: 100%;
            .artists {
              flex-grow: 1;
              position: relative;
              height: 100%;
              @for $i from 1 through 100 {
                .artist:nth-child(#{$i}) {
                  position: absolute;
                  width: #{(10 - $i) * 4}px;
                  height: #{(10 - $i) * 4}px;
                  border: 2px solid $gray;
                  border-radius: 50%;
                  top: #{(($i - 1) * 2) + 3}px;
                  z-index: #{(9 - $i) * 2};
                  @if $i > 1 {
                    opacity: 0.8;
                  }
                  font-size: #{(9 - $i) * 2}px;
                  @if $i < 3 {
                    padding-bottom: 2px;
                  } @else if $i == 3 {
                    padding-bottom: 1px;
                  }
                }
              }
              $offset: 0;
              @for $i from 1 through 100 {
                .artist:nth-last-child(#{$i}) {
                  right: #{$offset}px;
                }
                $width: ($i + 4) * 4;
                $offset: $offset + (($width) / 3);
              }
              .image {
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
              }
              .initials {
                background-color: $black;
                border: 1px solid $accent;
                display: flex;
                justify-content: center;
                align-items: center;
                color: $white;
              }
              .artist:nth-child(1)::after {
                content: "$";
                background-color: $accent;
                color: $white;
                font-size: 0.6rem;
                padding: 0 5px 1px;
                font-weight: 900;
                border-radius: 50%;
                position: absolute;
                right: -2px;
                top: -2px;
              }
            }
            .pen {
              margin-top: 2px;
              margin-left: 0.75rem;
              width: 25px;
              height: 25px;
              display: flex;
              justify-content: center;
              align-items: center;
              background: $accent;
              overflow: hidden;
              border-radius: 50%;
              img {
                object-fit: cover;
                width: 15px;
              }
            }
          }
        }
      }
      .artist-plans {
        .artist {
          display: flex;
          align-items: center;
          img {
            width: 32px;
            height: 32px;
            border-radius: 50%;
          }
          .initials {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 32px;
            width: 32px;
            font-family: sans-serif;
            font-size: 13px;
            border-radius: 50%;
            background-color: $black;
            border: 1px solid $accent;
            color: $white;
          }
          span {
            margin-left: 0.75rem;
            font-size: 0.95rem;
          }
        }
        .plan {
          vertical-align: middle;
          /* .base {
            font-size: 0.9rem;
          } */
          .pro {
            background-color: #eb355d;
            color: $white;
            font-size: 0.7rem;
            font-weight: 600;
            padding: 1px 2px 2px;
            border-radius: 3px;
          }
        }
        .actions {
          vertical-align: middle;
          .buttons {
            display: flex;
            width: 200px;
            margin-left: auto;
            button {
              height: 32px;
              padding: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              padding: 0 15px 2px;
              margin: 0 2px;
            }
          }
        }
      }
      .loading-table {
        td {
          width: 100%;
          text-align: center;
          span {
            color: $accent;
            margin-right: 10px;
            margin-bottom: 4px;
          }
          div {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      .no-data {
        td {
          width: 100%;
          text-align: center;
          div {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      .users {
        margin-top: 32px;
        .title {
          font-size: 1.1rem;
          margin-bottom: 10px;
        }
        table {
          text-align: left;
          width: 100%;
          thead {
            border-bottom-width: 0px;
            font-weight: 500;
            font-size: 1.1rem;
            th {
              vertical-align: bottom;
              &.right {
                text-align: right;
              }
            }
          }
          td {
            padding: 16px 50px 0 0;
            &.right {
              padding-right: 0;
              padding-left: 50px;
            }
            &.user {
              display: flex;
              .user-dp {
                display: flex;
                align-items: center;
                img {
                  width: 45px;
                  height: 45px;
                  border-radius: 50%;
                }
                .initials {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  height: 45px;
                  width: 45px;
                  font-family: sans-serif;
                  font-size: 13px;
                  border-radius: 50%;
                  background-color: $accent;
                  color: $white;
                }
              }
              .user-details {
                display: flex;
                flex-direction: column;
                margin-left: 10px;
                span {
                  line-height: 20px;
                  &:last-of-type {
                    margin-top: 4px;
                    font-size: 0.9rem;
                  }
                }
              }
              .user-details-one {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: 10px;
              }
            }
          }
          .remove-container {
            display: flex;
            align-items: center;
            justify-content: center;
            .remove {
              width: 24px;
              height: 24px;
              display: flex;
              justify-content: center;
              align-items: center;
              background: #e0e0e0;
              border-radius: 50%;
              cursor: pointer;
              outline: none;
              border: none;
              position: relative;
              &:hover {
                background: $accent;
              }
              .minus {
                background: $white;
                width: 8px;
                height: 2px;
              }
              .x {
                color: $white;
                position: absolute;
                font-size: 1.2rem;
                font-weight: 900;
                top: -6px;
              }
            }
          }
        }
        .add {
          display: flex;
          align-items: center;
          margin-top: 1rem;
          width: fit-content;
          &:hover {
            color: $accent;
            cursor: pointer;
          }
          .add-button {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 45px;
            width: 45px;
            font-family: sans-serif;
            font-size: 25px;
            border-radius: 50%;
            border: 1px $accent dashed;
            background-color: transparent;
            color: $accent;
            padding-bottom: 5px;
            margin-right: 1rem;
            &:hover {
              cursor: pointer;
              background: rgba($text, 0.3);
            }
            &:focus {
              outline: none;
            }
          }
        }
      }
      .no-loader {
        width: 24px;
        height: 10px;
      }
    }
    .logout {
      background-color: $bg;
      padding: 38px calc(5% + 3rem);
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        padding: 13px 40px;
        margin-left: 5rem;
      }
    }
    .buttons {
      display: flex;
      button {
        width: 136px;
        height: 44px;
      }
    }
  }
}
</style>
