<template>
  <div class="artist-settings">
    <Loader v-if="$apollo.queries.artist.loading" size="50" color="#FF035A" />
    <div class="content" v-else>
      <span class="section-title">Artist Plan</span>
      <div class="section-content">
        <div class="plans">
          <div v-if="!isPro" class="plan">
            <div class="details">
              <span class="name">Base - $49/Song</span>
              <span class="price"
                >Contracts covering splits, rights, and more.</span
              >
            </div>
            <div class="check">
              <img src="../assets/icons/Check.svg" />
            </div>
          </div>
          <div class="plan pro">
            <div class="details">
              <span class="name">Nvoko Pro - $299/Year</span>
              <span class="price">Unlimited catalog protection.</span>
            </div>
            <div v-if="isPro" class="action-pro">
              <div class="pen" @click="initatePlanConfirmModal('base')">
                <PenIcon :accent="false" :white="true" />
              </div>
              <!--
              <div class="artists">
                <div
                  class="artist"
                  v-for="{ id, avatar_url, name } in myArtists"
                  :key="id"
                  :class="{ image: !!avatar_url, initials: !avatar_url }"
                  :style="`background-image: url(${avatar_url})`"
                >
                  <span v-if="!avatar_url">{{ name | initials }}</span>
                </div>
              </div>
              -->
            </div>
            <div v-else class="action">
              <button
                type="button"
                class="primary"
                @click="initatePlanConfirmModal('pro')"
              >
                Upgrade
              </button>
            </div>
          </div>
        </div>
      </div>
      <span class="section-title">Artist Profile Settings</span>
      <div class="section-content profile">
        <div class="left-panel">
          <div class="profile-picture">
            <div class="avatar">
              <img
                v-if="!profile.avatar_url"
                class="placeholder"
                src="../assets/icons/User.svg"
              />
              <img v-else class="picture" :src="profile.avatar_url" />
            </div>
            <div class="replace-photo" @click="initateImageUpload('artist')">
              <img src="../assets/icons/Upload-Cloud.svg" />
              <span>Replace photo</span>
            </div>
            <!--
            <span class="upload-info">
              Max Photo Size: 1 MB
            </span>
            -->
          </div>
        </div>
        <div class="right-panel">
          <div class="profile-data">
            <span class="label">
              Artist Name
            </span>
            <input type="text" class="text-input" v-model="profile.name" />
            <div class="inline">
              <div>
                <span class="label">
                  PRO Affiliation
                </span>
                <input type="text" class="text-input" v-model="profile.pro" />
              </div>
              <div>
                <span class="label">
                  Songwriter IPI Number
                </span>
                <input
                  type="text"
                  class="text-input"
                  v-model="profile.ipi_songwriter"
                />
              </div>
            </div>
            <div class="inline">
              <div>
                <span class="label">
                  Publisher Name
                </span>
                <input
                  type="text"
                  class="text-input"
                  v-model="profile.publisher_name"
                />
              </div>
              <div>
                <span class="label">
                  Publisher IPI Number
                </span>
                <input
                  type="text"
                  class="text-input"
                  v-model="profile.ipi_publisher"
                />
              </div>
            </div>
            <span class="label">
              Representation
            </span>
            <CustomSelect
              :options="repOptions"
              @setValue="value => (profile.representation = value)"
              class="rep-selector"
              :val="profile.representation"
              noMargin
            />
          </div>

          <div class="buttons">
            <button
              class="primary"
              :disabled="!updated('profile') || submitting"
              @click="updateProfile"
            >
              {{ submitting ? "Updating..." : "Update" }}
            </button>
          </div>
        </div>
      </div>
      <span class="section-title">Artist Label or Loan-out Company</span>
      <div class="section-content">
        <div class="representation">
          <div v-if="!companies.length" class="item">
            <div class="details">
              <span class="name"
                >This artist does not have a label or loan-out company
                representing them.</span
              >
            </div>
          </div>
          <div
            class="item"
            v-for="({ id, name, avatar_url }, i) in companies"
            :key="i"
          >
            <div
              v-if="avatar_url"
              class="avatar image"
              :style="`background-image: url(${avatar_url})`"
            >
              <div class="camera" @click="initateImageUpload('company', id)">
                <img src="../assets/icons/Camera.svg" />
              </div>
            </div>
            <div class="avatar initials" v-else>
              <div class="camera" @click="initateImageUpload('company', id)">
                <img src="../assets/icons/Camera.svg" />
              </div>
              <span>{{ name | initials }}</span>
            </div>
            <div class="details">
              <span class="name">{{ name }}</span>
            </div>
          </div>
          <!--
          <div
            class="item"
            v-for="{
              id,
              user: { id: user_id, first_name, last_name, email, avatar_url }
            } in representatives"
            :key="'representative-' + id"
          >
            <div
              v-if="avatar_url"
              class="avatar image"
              :style="`background-image: url(${avatar_url})`"
            >
              <div class="camera" @click="initateImageUpload('user', user_id)">
                <img src="../assets/icons/Camera.svg" />
              </div>
            </div>
            <div class="avatar initials" v-else>
              <div class="camera" @click="initateImageUpload('user', user_id)">
                <img src="../assets/icons/Camera.svg" />
              </div>
              <span>{{ (first_name + " " + last_name) | initials }}</span>
            </div>
            <div class="details">
              <span class="name">{{ first_name + " " + last_name }}</span>
              <span class="email">{{ email }}</span>
            </div>
          </div>
          -->
          <!-- <div class="disconnect">
            <button class="primary outline">Disconnect</button>
          </div> -->
        </div>
      </div>
      <span class="section-title">Artist User Account</span>
      <span class="section-subtitle">
        This is the individual doing the creative work. If this artist is a
        group, then add all associated individuals.
      </span>
      <div class="section-content">
        <div class="users">
          <div
            class="item"
            v-for="{
              id,
              user: { id: user_id, first_name, last_name, email, avatar_url }
            } in artist_accounts"
            :key="'artist-account-' + id"
          >
            <div
              v-if="avatar_url"
              class="avatar image"
              :style="`background-image: url(${avatar_url})`"
            >
              <div class="camera" @click="initateImageUpload('user', user_id)">
                <img src="../assets/icons/Camera.svg" />
              </div>
            </div>
            <div class="avatar initials" v-else>
              <div class="camera" @click="initateImageUpload('user', user_id)">
                <img src="../assets/icons/Camera.svg" />
              </div>
              <span>{{ (first_name + " " + last_name) | initials }}</span>
            </div>
            <div class="details">
              <span class="name"
                >{{ first_name + " " + last_name }}
                <template v-if="user_id == user.id">(You)</template></span
              >
              <span class="email">{{ email }}</span>
            </div>
          </div>
          <div
            class="item add"
            @click="toggleAddNewUser('artist', 'Artist user account')"
          >
            <button class="add-button">+</button>
            <span>Add User</span>
          </div>
        </div>
      </div>
    </div>
    <ConfirmPlan
      v-if="planUpdateParams"
      :plan-update-params="planUpdateParams"
      @close="planUpdateParams = null"
    />
    <ImageUploader
      v-if="uploadParams"
      :upload-params="uploadParams"
      @close="uploadParams = null"
    />
    <AddArtistUser
      v-if="addUserParams"
      :params="addUserParams"
      @close="toggleAddNewUser"
    />
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import Loader from "@/components/Loader.vue";
import PenIcon from "@/assets/icons/Pen.vue";
import ImageUploader from "@/components/modals/ImageUploader.vue";
import AddArtistUser from "@/components/modals/AddArtistUser.vue";
import ConfirmPlan from "@/components/modals/ConfirmPlan.vue";
import CustomSelect from "@/components/Select.vue";

import GET_ARTIST from "@/api/queries/GET_ARTIST.gql";
import UPDATE_ARTIST_PROFILE from "@/api/mutations/UPDATE_ARTIST_PROFILE.gql";
import UPDATE_USER_PROFILE from "@/api/mutations/UPDATE_USER_PROFILE.gql";
import UPDATE_COMPANY from "@/api/mutations/UPDATE_COMPANY.gql";
import SUBSCRIBE from "@/api/mutations/SUBSCRIBE.gql";
import SUBSCRIPTION_ADD_ARTIST from "@/api/mutations/SUBSCRIPTION_ADD_ARTIST.gql";
import SUBSCRIPTION_REMOVE_ARTIST from "@/api/mutations/SUBSCRIPTION_REMOVE_ARTIST.gql";
import GET_MY_ARTISTS from "@/api/queries/GET_MY_ARTISTS.gql";

export default {
  name: "ArtistSettings",
  components: {
    Loader,
    PenIcon,
    ImageUploader,
    AddArtistUser,
    ConfirmPlan,
    CustomSelect
  },
  data() {
    return {
      profile: {},
      representatives: [],
      artist_accounts: [],
      addUserParams: null,
      uploadParams: null,
      planUpdateParams: null,
      submitting: false,
      repOptions: [
        { value: "none", label: "None" },
        { value: "label", label: "Label" },
        { value: "loan_out_company", label: "Loan-out Company" },
        {
          value: "loan_out_company_w_label",
          label: "Loan-out Company w/ Third-party Label"
        }
      ]
    };
  },
  watch: {
    artistId() {
      this.$apollo.queries.myArtists.refetch();
    }
  },
  computed: {
    user() {
      return this.$store.getters["account/getUser"];
    },
    companies() {
      let companies = [];
      this.representatives.forEach(({ company }) => {
        if (company) companies.push(company);
      });
      return companies;
    },
    artistId() {
      return this.$store.getters["account/getArtistId"];
    },
    isPro() {
      return !!this.user?.subscription?.artist_subscriptions.find(
        ({ artist_id }) => artist_id === this.profile?.id
      );
    }
  },
  filters: {
    initials(name) {
      return name
        .split(" ")
        .slice(0, 2)
        .map(el => el.charAt(0).toUpperCase())
        .join("");
    }
  },
  methods: {
    updated(val) {
      const { artist } = this;
      return Object.keys(artist[val]).some(
        el => artist[val][el] !== this[val][el]
      );
    },
    toggleAddNewUser(role, title) {
      const { companies, addUserParams, profile } = this;
      if (addUserParams) return (this.addUserParams = null);
      const artist_id = profile && profile.id;
      const company_id = companies[0] && companies[0].id;
      this.addUserParams = { role, title, artist_id, company_id };
    },
    initateImageUpload(type, id) {
      this.uploadParams = {
        save: url => {
          if (type === "artist") return this.updateArtistAvatar(url);
          if (type === "user") return this.updateUserAvatar(url, id);
          if (type === "company") return this.updateCompanyAvatar(url, id);
        }
      };
    },
    updateArtistAvatar(url) {
      const { id } = this.profile;

      this.$apollo.mutate({
        mutation: UPDATE_ARTIST_PROFILE,
        variables: { id, update: { avatar_url: url } },
        update: (store, { data: { update_artists_by_pk } }) => {
          this.$store.commit("account/updateArtist", update_artists_by_pk);
          const data = store.readQuery({
            query: GET_ARTIST,
            variables: {
              id: this.$store.getters["account/getArtistId"],
              userId: this.$store.getters["account/getUserId"]
            }
          });

          data.artists_by_pk.profile = {
            ...data.artists_by_pk.profile,
            avatar_url: update_artists_by_pk.avatar_url
          };

          store.writeQuery({
            query: GET_ARTIST,
            variables: {
              id: this.$store.getters["account/getArtistId"],
              userId: this.$store.getters["account/getUserId"]
            },
            data
          });

          this.uploadParams = null;
        }
      });
    },
    updateUserAvatar(url, id) {
      this.$apollo.mutate({
        mutation: UPDATE_USER_PROFILE,
        variables: { id, update: { avatar_url: url } },
        update: (store, { data: { update_users_by_pk } }) => {
          if (this.$store.getters["account/getUserId"] === id) {
            this.$store.commit("account/updateUser", update_users_by_pk);
          }

          const data = store.readQuery({
            query: GET_ARTIST,
            variables: {
              id: this.$store.getters["account/getArtistId"],
              userId: this.$store.getters["account/getUserId"]
            }
          });

          data.artists_by_pk.representatives = data.artists_by_pk.representatives.flatMap(
            el => {
              if (el.user.id !== id) return [el];
              return [{ ...el, user: { ...el.user, avatar_url: url } }];
            }
          );

          data.artists_by_pk.artist_accounts = data.artists_by_pk.artist_accounts.flatMap(
            el => {
              if (el.user.id !== id) return [el];
              return [{ ...el, user: { ...el.user, avatar_url: url } }];
            }
          );

          store.writeQuery({
            query: GET_ARTIST,
            variables: {
              id: this.$store.getters["account/getArtistId"],
              userId: this.$store.getters["account/getUserId"]
            },
            data
          });

          this.uploadParams = null;
        }
      });
    },
    updateCompanyAvatar(url, id) {
      this.$apollo.mutate({
        mutation: UPDATE_COMPANY,
        variables: { id, update: { avatar_url: url } },
        update: (store, { data: { update_companies_by_pk } }) => {
          const data = store.readQuery({
            query: GET_ARTIST,
            variables: {
              id: this.$store.getters["account/getArtistId"],
              userId: this.$store.getters["account/getUserId"]
            }
          });

          data.artists_by_pk.representatives = data.artists_by_pk.representatives.flatMap(
            el => {
              if (!el.company || el.company.id !== id) return [el];
              return [{ ...el, company: { ...el.company, avatar_url: url } }];
            }
          );

          store.writeQuery({
            query: GET_ARTIST,
            variables: {
              id: this.$store.getters["account/getArtistId"],
              userId: this.$store.getters["account/getUserId"]
            },
            data
          });

          this.uploadParams = null;
        }
      });
    },
    updateProfile() {
      this.submitting = true;

      const {
        id,
        name,
        avatar_url,
        ipi_publisher,
        ipi_songwriter,
        pro,
        publisher_name,
        representation
      } = this.profile;

      const variables = {
        id,
        update: {
          name,
          avatar_url,
          ipi_publisher,
          ipi_songwriter,
          pro,
          publisher_name,
          representation
        }
      };

      this.$apollo.mutate({
        mutation: UPDATE_ARTIST_PROFILE,
        variables,
        update: (store, { data: { update_artists_by_pk } }) => {
          this.$store.commit("account/updateArtist", update_artists_by_pk);
          this.submitting = false;
        }
      });
    },
    initatePlanConfirmModal(plan) {
      this.planUpdateParams = {
        plan,
        save: () => (plan === "pro" ? this.upgrade() : this.downgrade())
      };
    },
    upgrade() {
      const user = this.$store.getters["account/getUser"];
      return this.$apollo.mutate({
        mutation: user.subscription ? SUBSCRIPTION_ADD_ARTIST : SUBSCRIBE,
        variables: { artist_id: this.$store.getters["account/getArtistId"] },
        update: () => (this.planUpdateParams = null)
      });
    },
    downgrade() {
      return this.$apollo.mutate({
        mutation: SUBSCRIPTION_REMOVE_ARTIST,
        variables: { artist_id: this.$store.getters["account/getArtistId"] },
        update: () => (this.planUpdateParams = null)
      });
    }
  },
  apollo: {
    artist: {
      query: GET_ARTIST,
      variables() {
        return {
          id: this.$store.getters["account/getArtistId"],
          userId: this.$store.getters["account/getUserId"]
        };
      },
      update({ artists_by_pk }) {
        const { representatives, artist_accounts, ...profile } = artists_by_pk;
        this.profile = profile;
        this.representatives = representatives;
        this.artist_accounts = artist_accounts;

        return {
          profile: { ...profile },
          representatives: [...representatives],
          artist_accounts: [...artist_accounts]
        };
      }
    },
    myArtists: {
      query: GET_MY_ARTISTS,
      variables() {
        return { userId: this.$store.getters["account/getUserId"] };
      },
      update({ artists }) {
        const updated = [...artists];
        const id = this.$store.getters["account/getArtistId"];

        updated.forEach((item, i) => {
          if (item.id === id) {
            updated.splice(i, 1);
            updated.unshift(item);
          }
        });

        return updated;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
.artist-settings {
  width: 100%;
  height: 100%;
  .loader-container {
    height: calc(100vh - 110px) !important;
  }
  .content {
    padding-top: 3rem;
    overflow-y: auto;
    height: 100%;
    &::-webkit-scrollbar {
      /* width of the entire scrollbar */
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      /* color of the tracking area */
      @include background-opacity(#cdcccc, 0.3);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      /* color of the scroll thumb */
      background-color: $accent;
      /* roundness of the scroll thumb */
      border-radius: 5px;
    }
    .section-title {
      display: block;
      margin-left: calc(5% + 3rem);
      font-size: 1.15rem;
      font-weight: 600;
    }
    .section-subtitle {
      display: block;
      margin-top: 0.8rem;
      margin-left: calc(5% + 3rem);
      font-size: 0.9rem;
      font-weight: 200;
    }
    .section-content {
      margin: 0 5% 2rem;
      padding: 1.5rem 3rem;
      .plans {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .pro {
          border: 2px solid $accent;
        }
        .plan {
          width: 45%;
          background-color: $gray;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 12px 24px 14px;
          border-radius: 20px;
          .details {
            display: flex;
            flex-direction: column;
            .name {
              font-weight: bold;
              font-size: 0.9rem;
            }
            .price {
              font-size: 0.9rem;
              font-weight: 100;
            }
          }
          .check {
            margin-top: 2px;
            width: 25px;
            height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: $accent;
            overflow: hidden;
            border-radius: 50%;
            img {
              object-fit: cover;
              width: 15px;
            }
          }
          .action {
            button {
              margin-top: 2px;
              padding: 10px 18px 13px;
            }
          }
          .action-pro {
            flex-grow: 1;
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-start;
            align-items: center;
            height: 100%;
            .artists {
              flex-grow: 1;
              position: relative;
              height: 100%;
              @for $i from 1 through 100 {
                .artist:nth-child(#{$i}) {
                  position: absolute;
                  width: #{(10 - $i) * 4}px;
                  height: #{(10 - $i) * 4}px;
                  border: 2px solid $gray;
                  border-radius: 50%;
                  top: #{(($i - 1) * 2) + 3}px;
                  z-index: #{(9 - $i) * 2};
                  @if $i > 1 {
                    opacity: 0.8;
                  }
                  font-size: #{(9 - $i) * 2}px;
                  @if $i < 3 {
                    padding-bottom: 2px;
                  } @else if $i == 3 {
                    padding-bottom: 1px;
                  }
                }
              }
              $offset: 0;
              @for $i from 1 through 100 {
                .artist:nth-last-child(#{$i}) {
                  right: #{$offset}px;
                }
                $width: ($i + 4) * 4;
                $offset: $offset + (($width) / 3);
              }
              .image {
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                background-color: $accent;
              }
              .initials {
                background-color: $black;
                border: 1px solid $accent;
                display: flex;
                justify-content: center;
                align-items: center;
                color: $white;
              }
              .artist:nth-child(1)::after {
                content: "$";
                background-color: $accent;
                color: $white;
                font-size: 0.6rem;
                padding: 0 5px 1px;
                font-weight: 900;
                border-radius: 50%;
                position: absolute;
                right: -2px;
                top: -2px;
              }
            }
            .pen {
              margin-top: 2px;
              margin-left: 0.75rem;
              width: 25px;
              height: 25px;
              display: flex;
              justify-content: center;
              align-items: center;
              background: $accent;
              overflow: hidden;
              border-radius: 50%;
              img {
                object-fit: cover;
                width: 15px;
              }
            }
          }
        }
      }
      .left-panel {
        display: flex;
        flex-direction: column;
        .profile-picture {
          width: fit-content;
          display: flex;
          flex-direction: column;
          align-items: center;
          .avatar {
            width: 200px;
            height: 200px;
            overflow: hidden;
            border-radius: 50%;
            background-color: $accent;
            display: flex;
            align-items: center;
            justify-content: center;
            .picture {
              object-fit: cover;
              width: 100%;
              height: 100%;
            }
            .placeholder {
              width: 50%;
            }
          }
          .replace-photo {
            margin: 16px auto;
            border: none;
            background: none;
            display: flex;
            align-items: center;
            img {
              margin-right: 8px;
            }
            span {
              @include category-medium();
              color: $accent;
            }
            &:hover {
              cursor: pointer;
            }
          }
          .upload-info {
            @include body-light();
            color: #939393;
            display: block;
            text-align: center;
          }
        }
      }
      .right-panel {
        .profile-data {
          display: flex;
          flex-direction: column;
          .label {
            margin-bottom: 10px;
          }
          input {
            margin-bottom: 30px;
          }
          .inline {
            display: flex;
            justify-content: space-between;
            div {
              display: flex;
              flex-direction: column;
              width: 48%;
            }
          }
          .rep-selector {
            width: 48%;
          }
        }
        .buttons {
          display: flex;
          justify-content: flex-end;
          button {
            width: 136px;
            height: 44px;
          }
        }
      }
      .representation,
      .users {
        display: flex;
        .item {
          display: flex;
          .avatar {
            border-radius: 50%;
            position: relative;
            .camera {
              position: absolute;
              top: -2px;
              right: -2px;
              background-color: $white;
              padding: 5px;
              border-radius: 50%;
              border: 1px solid $accent;
              cursor: pointer;
            }
          }
          .initials {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 45px;
            width: 45px;
            font-family: sans-serif;
            font-size: 19px;
            background-color: $black;
            border: 1px solid $accent;
            color: $white;
          }
          .image {
            height: 45px;
            width: 45px;
            background-color: $accent;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
          }
          .details {
            margin-left: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .email {
              font-size: 0.8rem;
            }
          }
        }
      }
      .representation {
        flex-direction: row;
        .item {
          margin-right: 3rem;
        }
        .disconnect {
          margin-left: auto;
          button {
            color: $accent;
            border-width: 2px;
            padding-top: 11px;
            font-weight: 700;
          }
        }
      }
      .users {
        flex-direction: column;
        .item {
          margin-top: 1.5rem;
        }
        .add {
          width: fit-content;
          align-items: center;
          &:hover {
            color: $accent;
            cursor: pointer;
            .add-button {
              background-color: $accent;
              color: $white;
              border-style: solid;
            }
          }
          .add-button {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 45px;
            width: 45px;
            font-family: sans-serif;
            font-size: 30px;
            padding-bottom: 5px;
            border-radius: 50%;
            border: 1px $accent dashed;
            background-color: transparent;
            color: $accent;
            margin-right: 1rem;
            &:focus {
              outline: none;
            }
          }
        }
      }
    }
    .profile {
      display: grid;
      grid-template-columns: 30% 70% 1fr;
    }
  }
}
</style>
